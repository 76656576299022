import ApiService from "@/core/services/api.service";
import { StudentInformationService } from "@/core/services/studentInformationApi.service";
import { PedagogicalDiaryService } from "@/core/services/apis.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllStudents = () => {
  return ApiService.get("students");
};

/**
 * Get estudiantes para proceso de matrícula
 */
export const getEnrollableStudents = () => {
  return ApiService.get("students/enrollable");
};

export const getStudentPwdByCode = (code) => {
  return ApiService.get(`students/${code}/pwd`);
}

/**
 * GET request to fetch all data given a typed match
 * code
 * name
 * @returns {*}
 */
export const getStudentsOnTypedMatch = (searchTerm) => {
  return ApiService.get(`/students/search?query=${searchTerm}`);
};

/**
 * GET request to fetch all data of students without academic and technnical group
 * @returns {*}
 */
export const getAllStudentsHasNotGroups = () => {
  return ApiService.get("students/has_not_groups");
};

/**
 * GET request to fetch all data of students with academic information
 * @returns {*}
 */
export const getAllWithAcademicInformation = () => {
  return ApiService.get("students/all/academic-information", true);
};
/**
 * GET request to fetch all data of students with academic information
 * @returns {*}
 */
export const getAllWithAcademicInformationIncludingDisabled = (headers) => {
  return ApiService.get("students/all/academic-information", true, headers);
};

/**
 * GET request to fetch all data of students with enrollment process
 * @returns {*}
 */
export const getAllStudentsWithEnrollmentProcess = () => {
  return ApiService.get("students/enrollment_process");
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getStudent = (id) => {
  return ApiService.get("students", id);
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getStudentByAcademicGroup = (id) => {
  return ApiService.get("students/academic-group", id);
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getStudentByTechnicalGroup = (id) => {
  return ApiService.get("students/technical-group", id);
};


/**
 * POST request to create a new student
 * @param payload
 * @returns {*}
 */
export const createStudent = (payload) => {
  return ApiService.post("students", payload);
};

/**
 * PUT request to update a specific student
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateStudent = (id, payload) => {
  return ApiService.update("students", id, payload);
};

/**
 * DELETE request to delete the specified student
 * @param id
 * @returns {*}
 */

export const deleteStudent = (id) => {
  return ApiService.delete(`students/${id}`);
};

/**
 * GET request to change status of the specified student
 * @param id
 * @returns {*}
 */
export const changeStatusStudent = (id) => {
  return ApiService.get("students/status", id);
};

/**
 * GET request to fetch certificate of one student
 * @returns {*}
 */
export const getCertificatesOfStudent = (studentId) => {
  return ApiService.get("certificate_students/student", studentId);
};

/**
 * GET request to fetch documents of one student
 * @returns {*}
 */
export const getDocumentsOfStudent = (studentId) => {
  return ApiService.get("student_documents/student", studentId);
};

/**
 * GET request to fetch diseases of one student
 * @returns {*}
 */
export const getDiseasesOfStudent = (studentId) => {
  return ApiService.get("student_diseases/student", studentId);
};

/**
 * GET request to fetch relatives of one student
 * @returns {*}
 */
export const getRelativesOfStudent = (studentId) => {
  return ApiService.get("student_relatives/student", studentId);
};

/**
 * GET request to fetch medicines of one student
 * @returns {*}
 */
export const getMedicinesOfStudent = (studentId) => {
  return ApiService.get("student_medicines/student", studentId);
};

/**
 * GET request to fetch managers of one student
 * @returns {*}
 */
export const getManagersOfStudent = (studentId) => {
  return ApiService.get("student_managers/student", studentId);
};

/**
 * GET request to fetch sacraments of one student
 * @returns {*}
 */
export const getSacramentsOfStudent = (studentId) => {
  return ApiService.get("student_sacraments/student", studentId);
};

/**
 * GET request to fetch familiar informations of one student
 * @returns {*}
 */
export const getFamiliarInformationsOfStudent = (studentId) => {
  return ApiService.get("student_familiar_informations/student", studentId);
};

/**
 * GET request to fetch covid information of one student
 * @returns {*}
 */
export const getCovidInformationOfStudent = (studentId) => {
  return ApiService.get("student_covids/student", studentId);
};

/**
 * GET request to fetch a specific data
 * @param code
 * @returns {*}
 */
export const getStudentByCode = (code) => {
  return ApiService.get("students/code", code);
};

/**
 * GET request to fetch a specific data
 * @param code
 * @returns {*}
 */
export const getStudentEnrollmentByCode = (code) => {
  return ApiService.get("students/enrollment/code", code);
};

/**
 * GET request to fetch a specific data
 * @param code
 * @returns {*}
 */
export const getStudentsByTeacherGroup = (code) => {
  return ApiService.get("students/teacher-group", code);
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getStudentByTeacherGroupAndSection = (code, section) => {
  return ApiService.get(`students/teacher-group/${code}/section/${section}`);
};

/**
 * GET request to fetch covid information of one student
 * @returns {*}
 */
export const getEnrollmentProcessOfStudent = (studentId) => {
  return ApiService.get("student_enrollment_process/student", studentId);
};

/**
 * PUT request to update a specific enrollment process student
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateEnrollmentProcessOfStudent = (id, payload) => {
  return ApiService.update("student_enrollment_process", id, payload);
};

/**
 * POST request to assign academic and technical groups
 * @param payload
 * @returns {*}
 */
export const groupsAssignment = (payload) => {
  return ApiService.post("group_assignments", payload);
};

/**
 * GET request to retrieve student with scores
 * @returns {*}
 */
export const getStudentsScoresByAcademicGroup = ({
  sub_evaluation_id,
  academic_group_id,
}) => {
  return ApiService.get(
    `students/academic-group/${academic_group_id}/sub-evaluation/${sub_evaluation_id}`
  );
};

/**
 * GET request to retrieve student with scores
 * @returns {*}
 */
export const getStudentsScoresByTechnicalGroup = ({
  sub_evaluation_id,
  section,
  technical_group_id,
}) => {
  return ApiService.get(
    `students/technical-group/${technical_group_id}/section/${section}/sub-evaluation/${sub_evaluation_id}`
  );
};

export const verifyIfTeacherGuide = (id) => {
  return ApiService.get("teacher-guide-groups/verify", id);
};

export const getTechnicalStudent = (id) => {
  return ApiService.get("students/technical-group", id);
};

export const getAcademicStudents = (id) => {
  return ApiService.get("students/academic-group", id);
};
// ---------------------------------UUID--------------------------------------
/*The headers passed as a parameter contain the student UUID*/

//Form API endpoint (more detailed)
export const getStudentInformation = (headers) => {
  return StudentInformationService.get(`students/information`, "", headers);
};

//GE API endpoint (less detailed, just academic level details)
export const getStudentInformationByUUID = (studentUUID) => {
  return ApiService.get(`students/uuid/${studentUUID}`,);
};

export const getStudentDisease = (headers) => {
  return StudentInformationService.get(
    `student-diseases/diseases`,
    "",
    headers
  );
};

export const getStudentRelativeActive = (headers) => {
  return StudentInformationService.get(
    `student-relatives/relative/active`,
    "",
    headers
  );
};

export const getStudentRelativeGraduated = (headers) => {
  return StudentInformationService.get(
    `student-relatives/relative/graduated`,
    "",
    headers
  );
};

export const getStudentManager = (headers) => {
  return StudentInformationService.get(`student-managers`, "", headers);
};

export const getStudentPrescribedMedicine = (headers) => {
  return StudentInformationService.get(
    `student-medicines/medicine/prescribed`,
    "",
    headers
  );
};

export const getStudentAllergicMedicine = (headers) => {
  return StudentInformationService.get(
    `student-medicines/medicine/allergic`,
    "",
    headers
  );
};

export const getStudentScrament = (headers) => {
  return StudentInformationService.get(`student-sacraments`, "", headers);
};

export const getStudentFamiliarInformation = (headers) => {
  return StudentInformationService.get(
    `student-familiar-informations`,
    "",
    headers
  );
};

export const getStudentCovidInformation = (headers) => {
  return StudentInformationService.get(`student-covids`, "", headers);
};
// ---------------------------------------------------------------------------

export default {
  getAllStudents,
  getStudentsOnTypedMatch,
  getAllStudentsHasNotGroups,
  getAllStudentsWithEnrollmentProcess,
  getStudent,
  getStudentByAcademicGroup,
  getStudentByTechnicalGroup,
  createStudent,
  updateStudent,
  deleteStudent,
  changeStatusStudent,
  getCertificatesOfStudent,
  getDiseasesOfStudent,
  getDocumentsOfStudent,
  getRelativesOfStudent,
  getManagersOfStudent,
  getMedicinesOfStudent,
  getSacramentsOfStudent,
  getFamiliarInformationsOfStudent,
  getCovidInformationOfStudent,
  getStudentByCode,
  getStudentEnrollmentByCode,
  getEnrollmentProcessOfStudent,
  updateEnrollmentProcessOfStudent,
  groupsAssignment,
  getStudentsScoresByAcademicGroup,
  getStudentsScoresByTechnicalGroup,
  getAllWithAcademicInformation,
  verifyIfTeacherGuide,
  getAcademicStudents,
  getTechnicalStudent,
  getEnrollableStudents,
  getStudentPwdByCode,

  //Form API request
  getStudentInformation,
  getStudentInformationByUUID,
  getStudentDisease,
  getStudentRelativeGraduated,
  getStudentRelativeActive,
  getStudentManager,
  getStudentPrescribedMedicine,
  getStudentAllergicMedicine,
  getStudentScrament,
  getStudentFamiliarInformation,
  getStudentCovidInformation,
  getAllWithAcademicInformationIncludingDisabled,
  getStudentsByTeacherGroup,
  getStudentByTeacherGroupAndSection,
};
